var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("date-picker", {
    staticClass: "select-date-and-time",
    attrs: {
      "minute-step": _vm.minuteStep,
      placeholder: _vm.placeholder,
      type: _vm.option.typeOfDateAndTime,
      format: _vm.option.format,
      "value-type": _vm.option.returnValueFollowFormat,
      range: "",
    },
    model: {
      value: _vm.thisDatetime,
      callback: function ($$v) {
        _vm.thisDatetime = $$v
      },
      expression: "thisDatetime",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }